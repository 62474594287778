

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
    let event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, true)
    event.eventType = 'message'
    window.dispatchEvent(event)
}

/**
 * 触发 window.resize
 */
 export function isWeiXin() {
    var loginType = localStorage.getItem("loginType")
    if(loginType == "weiXin"){
        return true
    }else{
        return false
    }
}

/**
 * 过滤对象中为空的属性
 * @param obj
 * @returns {*}
 */
export function filterObj(obj) {
    if (!(typeof obj == 'object')) {
        return;
    }

    for (var key in obj) {
        if (obj.hasOwnProperty(key)
            && (obj[key] == null || obj[key] == undefined || obj[key] === '')) {
            delete obj[key];
        }
    }
    return obj;
}

/**
 * 时间格式化
 * @param value
 * @param fmt
 * @returns {*}
 */
export function formatDate(value, fmt) {
    var regPos = /^\d+(\.\d+)?$/;
    if (regPos.test(value)) {
        //如果是数字
        let getDate = new Date(value);
        let o = {
            'M+': getDate.getMonth() + 1,
            'd+': getDate.getDate(),
            'h+': getDate.getHours(),
            'm+': getDate.getMinutes(),
            's+': getDate.getSeconds(),
            'q+': Math.floor((getDate.getMonth() + 3) / 3),
            'S': getDate.getMilliseconds()
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (getDate.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        for (let k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
            }
        }
        return fmt;
    } else {
        //TODO
        value = value.trim();
        return value.substr(0, fmt.length);
    }
}

// 将'-'时间字符串变成时间
export function transferStringToDate(timeString) {
    const formatString = timeString.split('.');
    const dateAndTime = formatString[0].split('T');
    const dateArray = dateAndTime[0].split('-');
    const timeArray = dateAndTime[1].split(':');
    return new Date(dateArray[0], dateArray[1], dateArray[2], timeArray[0], timeArray[1], timeArray[2]);
}
/**
 * 时间格式化
 */
export function formateTime(time) {
    //参数时间
    const date = new Date(time)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    const formatNumber = n => {
        n = n.toString()
        return n[1] ? n : '0' + n
    }

    return year + '-' + month + '-' + day + ' ' + [hour, minute].map(formatNumber).join(':')
}
